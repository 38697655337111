import { HttpRequest } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ToastrService } from 'ngx-toastr';

import { LoginType } from 'src/app/shared/model/auth-channels';
import { IWebConfig } from 'src/app/IWebConfig';

/**
 * Generate a random password
 * @param length
 * @returns string
 */
export const generatePassword = (length: number): string => {
  const passwordLength = length;
  const includeLetters = true;
  const includeNumbers = true;
  const includeSymbols = true;

  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const symbols = '!@#$%^&*()_-,.';

  let availableCharacters = '';

  if (includeLetters) {
    availableCharacters += lowerCaseLetters;
    availableCharacters += upperCaseLetters;
  }

  if (includeNumbers) {
    availableCharacters += numbers;
  }

  if (includeSymbols) {
    availableCharacters += symbols;
  }

  availableCharacters.split('');
  const generatedPassword = [];

  for (let i = 0; i < passwordLength; i += 1) {
    const max = availableCharacters.length;
    const ran = Math.random();
    const idx = Math.floor(ran * (max + 1));

    generatedPassword.push(availableCharacters[idx]);
  }

  return generatedPassword.join('');
};

/**
 * Generate a random pass code (numbers only)
 * @param length
 * @returns string
 */
export const generateNumberPassword = (): string => {
  return Math.floor(1000 + Math.random() * 9000).toString();
};

/**
 * Format the mobile number
 * @param mobileNumber
 * @returns string
 */
export const formatMobileNumber = (mobileNumber: string): string => {
  const COUNTRY_CODE = '+27';

  const withoutLeadingZeros = removeLeadingZeros(mobileNumber);
  const validMobileNumber = COUNTRY_CODE + withoutLeadingZeros;

  // remove any spaces from the mobile number
  return validMobileNumber.replace(/ /g, '');
};

export const removeLeadingZeros = (mobileNumber: string): string => {
  const withoutLeadingZeros = parseInt(mobileNumber, 10);

  return withoutLeadingZeros.toString();
};

/**
 * Get the carousel images for the auth and onboarding screens
 * @returns any[]
 */
export const getCarouselImages = (): string => {
  return loadWebConfig().assets.authScreenBackground;
};

/**
 * Generate a djb2 hash number from a string
 * @param str
 * @returns number
 */
export const djb2Hash = (str: string): number => {
  var hash = 0;
  if (str.length == 0) return hash;

  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash = hash & hash;
  }

  return Math.abs(hash);
};

/**
 * Generate an array of urls and labels for breadcrumb navigation
 * @params routes, labels
 * @returns any[]
 */
export const getBreadcrumbItems = (routes: string[], labels: string[]): any[] => {
  const items = [];

  for (let i = 0; i < routes.length - 1; i++) {
    items.push({ route: routes[i], label: labels[i] });
  }

  return items;
};

/**
 * Check to see if a request contains a valid url protocol
 * @params req
 * @returns boolean
 */
export const isExternalUrl = (req: HttpRequest<any>) => {
  try {
    const url = new URL(req.url);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (error) {
    return false;
  }
};

/**
 * Gets which login type is configured for the portal
 * @returns LoginType.Password | LoginType.Pin
 */
export const getPasscodeLength = (loginType: string): number | null => {
  switch (loginType) {
    case LoginType.Password:
      return 8;
    case LoginType.Pin:
      return 4;
    default:
      return null;
  }
};

/**
 * Load webconfig from window object
 * @returns IWebConfig
 */
export const loadWebConfig = () => {
  var webconfig: IWebConfig = window['webconfig'];
  if (!webconfig) {
    console.error('webconfig.js not loaded.');
  }

  return webconfig;
};

/**
 * Format using current locale and currency
 * @returns string
 */
export const formatCurrency = (amount: number, currency: string, locale: string) => {
  return amount.toLocaleString(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/**
 * Convert base64 to image
 * @param base64 - The base64 string
 * @returns string
 */
export const convertBase64 = (base64: string) => {
  return base64 ? 'data:image/jpg;base64,' + base64 : null;
};

/**
 * Filter items by properties
 * Take a generic array of items and filter them by the properties provided to see if there is a match
 * @param items - The items / array to filter through
 * @param properties - The properties of the items to filter by
 * @param searchValue - The value to search for
 * @returns T[]
 */
export const searchItemsByProperties = <T>(items: T[], properties: (keyof T)[], searchValue: string): T[] => {
  const lowerSearchValue = searchValue.toLowerCase();

  return items.filter(
    (item) =>
      !searchValue || properties.some((property) => String(item[property]).toLowerCase().includes(lowerSearchValue)),
  );
};

/**
 * Download a table as a csv file
 * @param filename
 * @param data
 */
export const downloadTableAsCsv = (filename: string, data: any[]) => {
  if (data.length === 0) {
    return;
  }
  const replacer = (_key: any, value: any) => (value === null ? '' : value);
  const header = Object.keys(data[0]);
  const csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));

  const csvArray = csv.join('\r\n');
  const blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8;' });

  const downloadLink = document.createElement('a');
  const url = URL.createObjectURL(blob);

  downloadLink.setAttribute('href', url);
  downloadLink.setAttribute('download', filename + '.csv');
  downloadLink.style.visibility = 'hidden';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

/**
 * Map transactions to a new object
 * @returns any
 */
export const mapTransactions = (transactions: any[]): any => {
  return transactions.map((t) => {
    return {
      username: t.username,
      amount: t.amount,
      transactionFee: t.transactionFee,
      cardAssociation: t.cardAssociation,
      createdAt: t.createdAt,
      currency: t.currency,
      deviceInstallationId: t.deviceinstallationId,
      haloTransactionReference: t.haloTransactionReference,
      maskedPan: t.maskedPAN,
      merchantTransactionReference: t.merchantTransactionReference,
      transactionDisposition: t.transactionDisposition,
      transactionStatus: t.transactionstatus,
      transactionType: t.transactionType,
    };
  });
};

export const addButtonToCodeBlocks = (): boolean => {
  const codeBlocks = document.querySelectorAll('pre');
  let success = false;

  codeBlocks.forEach((pre) => {
    if (!pre.querySelector('button')) {
      const copy = document.createElement('button');
      copy.innerHTML = 'Copy';
      copy.addEventListener('click', async () => {
        try {
          await navigator.clipboard.writeText(pre.textContent);
        } catch (error) {
          throw Error('Unable to copy content to clipboard', error);
        }
      });

      pre.append(copy);
      success = true;
    }
  });

  return success;
};

/**
 * Converts a given date to ISO format with the timezone
 * @param date Date to be converted to ISO format with timezone
 * @returns Converted date with timezone
 */
export const getTimezonedISODate = (date: Date): String => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

/**
 * Longform password pattern
 */
export const PASSWORD_PATTERN = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/;

/**
 * 4 digit pin pattern
 */
export const PIN_PATTERN = /^\d{4}$/;

/**
 * Mobile number pattern
 */
export const MOBILE_PATTERN = /^[+0-9]{6,15}$/;

/**
 * RSA 2048 public key pattern
 */
export const RSA_2048_PUBLIC_KEY_PATTERN = /^ssh-rsa AAAA[0-9A-Za-z+/]+[=]{0,3}( [^@]+@[^@]+)?$/;

export const OPEN_SSL_PUBLIC_KEY_PATTERN = /^-----BEGIN PUBLIC KEY-----\s*([\w+/=\s\n]+)\s*-----END PUBLIC KEY-----$/;
/**
 * Default message when no rendered docs are available
 */
export const NO_RENDERED_DOCS = `
  ### Documentation Unavailable

  We're sorry, but the documentation you are looking for is currently unavailable. Our team is working hard to resolve this issue.

  Please check back later for updates. If you have any urgent questions or concerns, feel free to contact our support team.
`;
