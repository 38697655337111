import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';

import { Transactions } from 'src/app/shared/model/transactions.model';
import { TerminalListResponse } from 'src/app/shared/model/terminal-list-response.model';
import { UserListResponse } from 'src/app/shared/model/user-list-response.model';
import { IntegratorListResponse } from 'src/app/shared/model/integrator-list-response.model';
import { TerminalResponse } from 'src/app/shared/model/terminal-response.model';
import { TransactionResponse } from 'src/app/shared/model/transaction-response.model';
import { IntegratorResponse } from 'src/app/shared/model/integrator-response.model';
import { Receipt } from 'src/app/shared/model/receipt.model';
import { Token } from 'src/app/shared/model/token.model';
import { MerchantDetails } from 'src/app/shared/model/merchant-details.model';
import { StatementResponse } from 'src/app/shared/model/statement-response.model';
import { DashboardInfo } from 'src/app/shared/model/dashboardinfo.model';
import { User } from 'src/app/shared/model/user.model';
import { TransactionAuthResponse } from 'src/app/shared/model/transaction-auth-response.model';
import { ServerKey } from 'src/app/shared/model/server-key.model';
import { Transaction } from 'src/app/shared/model/transaction.model';
import { Country } from 'src/app/shared/model/country.model';
import { Integrator } from 'src/app/shared/model/integrator.model';
import { TransactionFeesConfigsListResponse } from 'src/app/shared/model/transaction-fees-configs-response.model';
import { TransactionFeesBreakdownListResponse } from 'src/app/shared/model/transaction-fees-breakdown-response.model';
import { MerchantBalances } from 'src/app/shared/model/merchant-balances.model';
import { PayoutRequestListResponse } from 'src/app/shared/model/payout-request-list-response.model';
import { ValidateBranchResponse } from 'src/app/shared/model/validate-branch-response.model';
import { CheckReferenceResponse } from 'src/app/shared/model/check-reference-response.model';
import { DashboardStatistics } from 'src/app/shared/model/dashboard-statistics.model';
import { TerminalAllocation } from 'src/app/shared/model/terminal-allocation.model';
import { QRCodeResponse } from 'src/app/shared/model/qr-code-response.model';
import { UserPreferences } from 'src/app/shared/model/userPreferences.model';
import { PortalDistributionListResponse } from 'src/app/shared/model/portal-distribution-list-response.model';
import { TerminalDeviceDetails } from 'src/app/shared/model/terminal-device-details.model';
import { BackofficeSearchResult } from 'src/app/shared/model/backoffice-search-result.model';
import { ApiKeyResponse } from 'src/app/shared/model/api-key-response.model';
import { IWebConfig } from 'src/app/IWebConfig';
import { loadWebConfig, getTimezonedISODate } from 'src/app/shared/utils/utils';
import { QrCodeResponse } from '../model/qr-code.model';
import { ConsummerTransactionStatus } from '../model/consumer-transaction.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public appSocketServer: string;
  public softPosUrl: string;
  public androidSampleApp: string;
  public cordovaSampleApp: string;
  public intentsIntegrationGuide: string;
  public haloNdaDocument: string;
  public haloDeveloperDocs: string;
  public haloGithubRepo: string;
  public haloGoAndroidApp: string;
  public vodacomFraudFlyer: string;

  private webconfig: IWebConfig = loadWebConfig();
  private countriesJson = '../../assets/data/countries.json';

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
  ) {
    this.androidSampleApp = 'android_sample_app.zip';
    this.cordovaSampleApp = 'cordova_sample_app.zip';
    this.intentsIntegrationGuide = 'intents_integration_guide.pdf';
    this.vodacomFraudFlyer = 'vodacom_fraud_flyer.pdf';
    this.haloNdaDocument = 'nda_halo_dot_synthesis.pdf';
    this.haloDeveloperDocs = 'https://halo-dot-developer-docs.gitbook.io/halo-dot/';
    this.haloGithubRepo = 'https://github.com/halodot/';
    this.haloGoAndroidApp =
      'https://play.google.com/store/apps/details?id=za.co.synthesis.halo.mpos.go&pcampaignid=web_share';
    this.softPosUrl =
      'https://halo-buildbox-storage1.s3.eu-west-1.amazonaws.com/apks/app-go-release-protected.apk?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWV4A6Y2UKBONJK7L%2F20221024%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20221024T071458Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEOD%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCWV1LXdlc3QtMSJGMEQCIAUS2dfq22v4%2F7rN9%2Bo4Sj%2FUbY8XgNqoOukZiHF%2B0HA1AiAc1LF0olpFH2exYE5bZ77voM7eU56qw7mUHwBgDt%2FliiqfAwi4%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAMaDDQ1OTI5NTA4MjE1MiIMmAKRHFy1d1MV%2BkUXKvMCG64Imyz60zH8rHUuIKT%2BVUffG%2BrcwjM1Qnjf0wlbtxgixh2Jwl6t3bEzy2dN4LLLtSMhklpE3X2k1Lq3aRhr2jwO3%2B0bi6aazGlgNJa9exmAsfMlHQMwJbYAUJFVU4XNekCv60Wp2YM7bhGJV8C4GEcIeV%2FqLrl5mYk2xd%2FcX8W4VWJysGiyMHem%2FWUYXZ%2BoSueLTyXjSftW5q3ztVo8RQZSXgFLDWPnZQJWMWRon3e8y%2BxF52Koj6cwH%2FOYwg2RuGCAe%2FLLSFSi5TAeLELaRQX%2FZZFfO75brVjzjI483vRt%2BfKTJUCm9qoxo4wJoM2Ookvg8B2%2BF1gH9a8oQDjEPbpuY0ewlJfPf%2BITY5vFs3aQapgmfIHRopmn7B%2FQVz99J%2BPLLafVHhkPHr7czrBc7huXAhVtMDq6JMwi%2BB2VNMdp3qWCOKyaNB9IVfX%2F7FTl0aMDOjQ4PXuTYnDa2eXX6wrR0Dkz999qT%2FE%2FYofTiH2FyRowlPbYmgY6pwFgMpg1RTKCdswbZQFKOimOkZCiCVyfrCKAVMKrxyonSdFe72LbO6hTMf9SswahHC%2BE5AGMjzAuQok1HDs1mc44fczRU2LrEjUqT2oguW0nIAJpEupBxx3hNl5%2BYoHcpjvjrDOPRR9iucTZb282YjbuQYteE40qb2%2Bpml9PFh%2BYnpOpYE%2F%2FTujcj0PBNLClVzsKqCEo5h7HljOZPwC%2Bpnk86wXBcDtTpA%3D%3D&X-Amz-Signature=fa52995c0e3a3706454eb4c921a6a7f5ece863d15ebe085f34f7bc179d883314';
  }

  public get webConfig(): IWebConfig {
    return this.webconfig;
  }

  public registerJwtResponse(): Promise<{ token: string }> {
    try {
      const url = `${this.webconfig.baseUrls.auth}/register/JWT`;

      return this.http.request<{ token: string }>('GET', url).toPromise();
    } catch (error) {
      this.handleError('An error occurred during registerJwtResponse:', error);
    }
  }

  public async getBranding(appPackageName: string) {
    const url = `${this.webconfig.baseUrls.auth}/branding?appId=${appPackageName}`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest('GET', url, null, headers)
      .pipe(
        map((branding: any) => {
          return {
            termsAndConditionsUrl: branding.termsAndConditionsUrl
              ? branding.termsAndConditionsUrl
              : this.webconfig.assets.termsAndConditionsUrl,
          };
        }),
      )
      .toPromise();
  }

  public async generateOtp(otpDetails: any) {
    const url = `${this.webconfig.baseUrls.auth}/register/generatecode`;
    const body = { ...otpDetails };
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest<any>('POST', url, body, headers).toPromise();
  }

  public async verifyOtp(verifyOtp: any) {
    const url = `${this.webconfig.baseUrls.auth}/register/verify`;
    const body = { ...verifyOtp };
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest<any>('POST', url, body, headers).toPromise();
  }

  public async createMerchant(merchantAccount: any) {
    const url = `${this.webconfig.baseUrls.auth}/register/merchant`;
    const body = { ...merchantAccount };
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest('POST', url, body, headers).toPromise();
  }

  public async updateMerchant(merchantAccount: any) {
    const url = `${this.webconfig.baseUrls.auth}/register/update`;
    const body = { ...merchantAccount };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async getMerchantCompleteness() {
    const url = `${this.webconfig.baseUrls.auth}/register/completeness`;

    return this.httpRequest<any>('GET', url).toPromise();
  }

  public async getIntegratorCompleteness() {
    const url = `${this.webconfig.baseUrls.auth}/register/integrator/completeness`;

    return this.httpRequest<any>('GET', url).toPromise();
  }

  public async getUserByUsername(username: string) {
    const url = `${this.webconfig.baseUrls.auth}/user/${username}`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };
    return this.httpRequest<User>('GET', url, null, headers).toPromise();
  }

  public async getUserByEmail(email: string) {
    const url = `${this.webconfig.baseUrls.auth}/user/email/${email}`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };
    return this.httpRequest<User>('GET', url, null, headers).toPromise();
  }

  public async getUserByMobile(mobile: string) {
    const url = `${this.webconfig.baseUrls.auth}/user/phoneNumber/${mobile}`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };
    return this.httpRequest<User>('GET', url, null, headers).toPromise();
  }

  public async getUserById(id: string) {
    const url = `${this.webconfig.baseUrls.auth}/user/id/${id}`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest<User>('GET', url, null, headers).toPromise();
  }

  public async isUsernameAvailable(username: string) {
    const url = `${this.webconfig.baseUrls.auth}/user/check/${username}`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest<{ available: boolean }>('GET', url, null, headers).toPromise();
  }

  public async isEmailAvailable(email: string) {
    const url = `${this.webconfig.baseUrls.auth}/user/checkEmail/${email}`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest<{ available: boolean }>('GET', url, null, headers).toPromise();
  }

  public async isMobileNumberAvailable(phoneNumber: string) {
    const url = `${this.webconfig.baseUrls.auth}/user/checkPhoneNumber/${phoneNumber}`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest<{ available: boolean }>('GET', url, null, headers).toPromise();
  }

  public async getCurrentUser() {
    const url = `${this.webconfig.baseUrls.auth}/user`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest<User>('GET', url, null, headers).toPromise();
  }

  public async updateUserById(user: any) {
    const url = `${this.webconfig.baseUrls.app}/merchants/users/${user.id}`;
    const body = { ...user, id: undefined };
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest('PUT', url, body, headers).toPromise();
  }

  public async getUserRoles(username: string) {
    const url = `${this.webconfig.baseUrls.auth}/user/roles/${username}`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest<any>('GET', url, null, headers).toPromise();
  }

  public async generateApiKey(): Promise<ApiKeyResponse> {
    const url = `${this.webconfig.baseUrls.auth}/user/generateapikey`;
    const body = {};

    return this.httpRequest<ApiKeyResponse>('POST', url, body).toPromise();
  }

  public async login(username: string, password: string) {
    const url = `${this.webconfig.baseUrls.auth}/login`;
    const body = { username, password };

    return this.httpRequest<Token>('POST', url, body).toPromise();
  }

  public async forgotPassword(details: any) {
    const url = `${this.webconfig.baseUrls.auth}/user/forgotpassword`;
    const body = { ...details };
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest('POST', url, body, headers).toPromise();
  }

  public async resetPasswordViaEmail(address: string) {
    const url = `${this.webconfig.baseUrls.auth}/user/passwordResetViaEmail`;
    const body = { address };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async resetPasswordWithToken(token: string, password: string) {
    const url = `${this.webconfig.baseUrls.auth}/user/resetPasswordWithToken`;
    const body = { token, password };
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest('POST', url, body, headers).toPromise();
  }

  public async getRefreshToken() {
    const url = `${this.webconfig.baseUrls.auth}/refresh/generateRefreshToken`;

    return this.httpRequest<Token>('GET', url).toPromise();
  }

  public async getAccessToken(refreshToken: string) {
    const url = `${this.webconfig.baseUrls.auth}/refresh/generateAccessToken`;
    const headers = {
      'x-refresh-token': refreshToken,
    };

    return this.httpRequest<Token>('GET', url, null, headers).toPromise();
  }

  public async getCurrentMerchant(): Promise<MerchantDetails> {
    const url = `${this.webconfig.baseUrls.auth}/register/merchant`;

    return this.httpRequest<MerchantDetails>('GET', url)
      .pipe(map((response) => ({ ...response })))
      .toPromise();
  }

  public async getMerchantById(merchantId: string): Promise<MerchantDetails> {
    const url = `${this.webconfig.baseUrls.auth}/register/merchant/${merchantId}`;

    return this.httpRequest<MerchantDetails>('GET', url)
      .pipe(map((response) => ({ ...response })))
      .toPromise();
  }

  public async getMerchantConfig() {
    const url = `${this.webconfig.baseUrls.auth}/register/config`;

    return this.httpRequest<any>('GET', url)
      .pipe(map((response) => ({ ...response, encryptionCert: undefined })))
      .toPromise();
  }

  public async getCurrentIntegrator(): Promise<Integrator> {
    const url = `${this.webconfig.baseUrls.auth}/register/integrator`;

    return this.httpRequest<Integrator>('GET', url).toPromise();
  }

  public async updateIntegrator(integratorAccount: any) {
    const url = `${this.webconfig.baseUrls.auth}/register/integrator`;
    const body = { ...integratorAccount };

    return this.httpRequest('PUT', url, body).toPromise();
  }

  public async createIntegrator(integratorAccount: any) {
    const url = `${this.webconfig.baseUrls.auth}/register/integrator`;
    const body = { ...integratorAccount };
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest('POST', url, body, headers).toPromise();
  }

  public async addIntegratortoUser(integratorAccount: any) {
    const url = `${this.webconfig.baseUrls.auth}/register/addIntegratortoUser`;
    const body = { ...integratorAccount };
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest('POST', url, body, headers).toPromise();
  }

  public async exchangeKeys(siteKey: string) {
    const jwtResponse = await this.registerJwtResponse();
    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/exchangeKeys`;
    const body = { siteKey };
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest<ServerKey>('POST', url, body, headers).toPromise();
  }

  // ----------------------------------------------
  // API Calls for Kernel Server
  // ----------------------------------------------

  public async getMerchantPreferences(merchantId: string) {
    const url = `${this.webconfig.baseUrls.kernel}/merchants/preferences/${merchantId}`;

    return this.httpRequest<UserPreferences>('GET', url).toPromise();
  }

  public async updateMerchantPreferences(userPreferences: UserPreferences) {
    const url = `${this.webconfig.baseUrls.kernel}/merchants/preferences`;
    const body = { ...userPreferences };

    return this.httpRequest('PUT', url, body).toPromise();
  }

  public async generateFees(transactionId: string) {
    const url = `${this.webconfig.baseUrls.kernel}/transactions/${transactionId}/generateFees`;

    return this.httpRequest<any>('GET', url).toPromise();
  }

  public async getFeesConfigs(): Promise<TransactionFeesConfigsListResponse> {
    const url = `${this.webconfig.baseUrls.kernel}/transactions/fees/configs`;

    return this.httpRequest<any>('GET', url)
      .pipe(
        map((feeConfig) => {
          return {
            feeConfigs: feeConfig,
          };
        }),
      )
      .toPromise();
  }

  public async getFeesConfig(transactionFeesConfigId: string) {
    const url = `${this.webconfig.baseUrls.kernel}/transactions/fees/config/${transactionFeesConfigId}`;

    return this.httpRequest<any>('GET', url).toPromise();
  }

  public async updateFeesConfig(feesConfig: any, transactionFeesConfigId: string) {
    const url = `${this.webconfig.baseUrls.kernel}/transactions/fees/config/${transactionFeesConfigId}`;
    const body = { feesConfig, id: undefined };

    return this.httpRequest('PUT', url, body).toPromise();
  }

  public async createFeesConfig(feesConfig: any) {
    const url = `${this.webconfig.baseUrls.kernel}/transactions/fees/config`;
    const body = { feesConfig };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async deleteFeesConfig(transactionFeesConfigId: number) {
    const url = `${this.webconfig.baseUrls.kernel}/transactions/fees/config/${transactionFeesConfigId}`;

    return this.httpRequest<void>('DELETE', url).toPromise();
  }

  public async getFeesBreakdown(transactionId: string): Promise<TransactionFeesBreakdownListResponse> {
    const url = `${this.webconfig.baseUrls.kernel}/transactions/${transactionId}/fees/breakdown`;

    return this.httpRequest<any>('GET', url).toPromise();
  }

  public async getMerchantBalance() {
    const url = `${this.webconfig.baseUrls.kernel}/merchants/balance`;

    return this.httpRequest<MerchantBalances>('GET', url).toPromise();
  }

  public async requestPayout(payoutRequest: any) {
    const url = `${this.webconfig.baseUrls.kernel}/merchants/payout/request`;
    const body = { ...payoutRequest };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async updateMerchantBalance(transactionId: string) {
    const url = `${this.webconfig.baseUrls.kernel}/merchants/updateBalance/${transactionId}`;

    return this.httpRequest<any>('GET', url).toPromise();
  }

  public async emailPdfStatement(address: string, dateFrom: Date, dateTo: Date) {
    const fromDate = getTimezonedISODate(dateFrom);
    const toDate = getTimezonedISODate(dateTo);

    const url = `${this.webconfig.baseUrls.kernel}/register/merchant`;
    const body = {
      address,
      fromDate,
      toDate,
    };

    return this.httpRequest<StatementResponse>('POST', url, body).toPromise();
  }

  public async generatePdfStatement(dateFrom: Date, dateTo: Date) {
    const fromDate = getTimezonedISODate(dateFrom);
    const toDate = getTimezonedISODate(dateTo);

    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/statement/pdf?from=${fromDate}&to=${toDate}`;
    return this.httpRequest<StatementResponse>('GET', url).toPromise();
  }

  public async getIntegrators(): Promise<IntegratorListResponse> {
    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/integrators`;

    return this.httpRequest<any>('GET', url)
      .pipe(
        map((integrator) => {
          return {
            integrators: integrator,
          };
        }),
      )
      .toPromise();
  }

  public async getIntegratorById(integratorId: string): Promise<IntegratorResponse> {
    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/integrator/${integratorId}`;
    const body = {
      params: { integratorId: integratorId },
    };

    return this.httpRequest<Integrator>('GET', url, body)
      .pipe(
        map((integrator) => {
          return { integrator: integrator };
        }),
      )
      .toPromise();
  }

  public async updateIntegratorStatus(integratorId: number, status: string): Promise<{ integratorId: number }> {
    const url = `${this.webconfig.baseUrls.app}/integrator/status/${integratorId}`;
    const body = { status: status };

    return this.httpRequest<{ integratorId: number }>('POST', url, body).toPromise();
  }

  public async getIntegratorUserById(userId: string) {
    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/integratorUsers/${userId}`;

    return this.httpRequest<User>('GET', url).toPromise();
  }

  public async getTransactionCount(params: any): Promise<{ count: number }> {
    const queryParams = '?' + new URLSearchParams({ ...params }).toString();
    const url = `${this.webconfig.baseUrls.kernel}/transactions/count/all${queryParams}`;

    return this.httpRequest<{ count: number }>('GET', url).toPromise();
  }

  public async getMerchantTransactions(params: any): Promise<Transactions> {
    const queryParams = '?' + new URLSearchParams({ ...params }).toString();
    const url = `${this.webconfig.baseUrls.kernel}/merchants/reports/transactions${queryParams}`;

    return this.httpRequest<any>('GET', url).toPromise();
  }

  public async getTransactions(params: Record<string, string>) {
    const queryParams = '?' + new URLSearchParams({ ...params }).toString();
    const url = `${this.webconfig.baseUrls.kernel}/transactions${queryParams}`;

    return this.httpRequest<Transaction[]>('GET', url).toPromise();
  }

  public async getTransaction(transactionRef: string): Promise<TransactionResponse> {
    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/transaction/${transactionRef}`;
    const body = {
      params: { transactionRef: transactionRef },
    };

    return this.httpRequest<Transaction>('GET', url, body)
      .pipe(map((transaction) => ({ transaction: transaction })))
      .toPromise();
  }

  public async checkReference(transactionRef: string): Promise<CheckReferenceResponse> {
    const url = `${this.webconfig.baseUrls.kernel}/transactions/references/check`;
    const body = {
      merchantReference: transactionRef,
    };

    return this.httpRequest<CheckReferenceResponse>('POST', url, body).toPromise();
  }

  public async refundTransaction(
    transactionRef: string,
    amount: number,
    refundReference: string,
  ): Promise<TransactionAuthResponse> {
    const url = `${this.webconfig.baseUrls.kernel}/transactions/${transactionRef}/refund`;
    const body = {
      amount,
      refundReference,
    };

    return this.httpRequest<any>('GET', url, body)
      .pipe(
        map((resp) => {
          let transformedResponse = {};
          if (resp.hasOwnProperty('onlineAuthorizationResponse')) {
            transformedResponse = {
              type: resp['onlineAuthorizationResponse']?.type,
              authorizationCode: resp['onlineAuthorizationResponse']?.authorizationCode,
              haloReference: resp['onlineAuthorizationResponse']?.haloReference,
              errorMessage: resp['onlineAuthorizationResponse']?.errorMessage,
              isoResponseCode: resp['onlineAuthorizationResponse']?.isoResponseCode,
              association: resp['onlineAuthorizationResponse']?.association,
              expiryDate: resp['onlineAuthorizationResponse']?.expiryDate,
              paymentProviderReference: resp['onlineAuthorizationResponse']?.paymentProviderReference,
            };
          }
          return transformedResponse as TransactionAuthResponse;
        }),
      )
      .toPromise();
  }

  public async getPayoutRequests(): Promise<PayoutRequestListResponse> {
    const url = `${this.webconfig.baseUrls.kernel}/merchants/payoutRequests`;

    return this.httpRequest<any>('GET', url)
      .pipe(
        map((payoutRequest) => {
          return {
            payoutRequests: payoutRequest,
          };
        }),
      )
      .toPromise();
  }

  public async getMerchantDevicesForUser() {
    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/terminals`;

    return this.httpRequest<TerminalListResponse>('GET', url).toPromise();
  }

  public async getMerchantDevice(terminalId: string): Promise<TerminalResponse> {
    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/terminal/${terminalId}`;
    const body = {
      params: { terminalId: terminalId },
    };

    return this.httpRequest<any>('GET', url, body)
      .pipe(map((response) => ({ terminal: response })))
      .toPromise();
  }

  public async blockTerminal(terminalId: string, reason: string) {
    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/blockTerminal/${terminalId}`;
    const body = { reason };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async unblockTerminal(terminalId: string, reason: string) {
    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/unblockTerminal/${terminalId}`;
    const body = { reason };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async sendReceipt(transactionRef: string, receipt: Receipt) {
    const url = `${this.webconfig.baseUrls.kernel}/transactions/${transactionRef}/receipt`;
    const body = receipt;

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async getDashboardInfo() {
    const url = `${this.webconfig.baseUrls.kernel}/merchantPortal/dashboard`;

    return this.httpRequest<DashboardInfo>('GET', url).toPromise();
  }

  public async getEcommerceQrCode(args: any): Promise<QrCodeResponse> {
    const url = `${this.webconfig.baseUrls.kernel}/consumer/qrCode`;
    const body = args;

    return this.httpRequest<QrCodeResponse>('POST', url, body).toPromise();
  }

  public getConsummerTransactionStatus(reference: string): Observable<ConsummerTransactionStatus> {
    const url = `${this.webconfig.baseUrls.kernel}/consumer/qrCode/${reference}`;

    return this.httpRequest<ConsummerTransactionStatus>('GET', url);
  }

  public async getAllMerchantUsers(): Promise<UserListResponse> {
    const url = `${this.webconfig.baseUrls.app}/merchants/users`;

    return this.httpRequest<any>('GET', url)
      .pipe(
        map((user) => {
          return {
            users: user,
          };
        }),
      )
      .toPromise();
  }

  public async getMerchantUsers(merchantId: string): Promise<UserListResponse> {
    const url = `${this.webconfig.baseUrls.app}/merchants/${merchantId}/users`;

    return this.httpRequest<any>('GET', url)
      .pipe(
        map((user) => {
          return {
            users: user,
          };
        }),
      )
      .toPromise();
  }

  // ----------------------------------------------
  // API Calls for App Server
  // ----------------------------------------------

  public async getMerchantUser(userId: string) {
    const url = `${this.webconfig.baseUrls.app}/merchants/users/${userId}`;

    return this.httpRequest<User>('GET', url).toPromise();
  }

  public async getIntegratorUsers() {
    const url = `${this.webconfig.baseUrls.app}/integrator/users`;

    return this.httpRequest<User[]>('GET', url).toPromise();
  }

  public async getIntegratorUser(userId: string) {
    const url = `${this.webconfig.baseUrls.app}/integrator/users/${userId}`;

    return this.httpRequest<User>('GET', url).toPromise();
  }

  public async createUser(user: any) {
    const url = `${this.webconfig.baseUrls.app}/merchants/users`;
    const body = { ...user };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async deleteUser(userId: string) {
    const url = `${this.webconfig.baseUrls.app}/merchants/users/${userId}`;

    return this.httpRequest('DELETE', url).toPromise();
  }

  public async restoreUser(userId: string) {
    const url = `${this.webconfig.baseUrls.app}/merchants/users/${userId}/undelete`;
    const body = {};

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async isMerchantNameAvailable(name: string) {
    const url = `${this.webconfig.baseUrls.app}/merchants/check/${name}`;
    const jwtResponse = await this.registerJwtResponse();
    const headers = {
      Authorization: `Bearer ${jwtResponse.token}`,
    };

    return this.httpRequest<{ available: boolean }>('GET', url, null, headers).toPromise();
  }

  public async createMerchantUser(merchantId: string, user: any) {
    const url = `${this.webconfig.baseUrls.app}/merchants/${merchantId}/users`;
    const body = { ...user };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async supportDeleteUser(userId: string) {
    const url = `${this.webconfig.baseUrls.app}/support/users/${userId}`;

    return this.httpRequest('DELETE', url).toPromise();
  }

  public async supportRestoreUser(userId: string) {
    const url = `${this.webconfig.baseUrls.app}/support/users/${userId}/undelete`;
    const body = {};

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async supportBlockTerminal(terminalId: string, reason: string) {
    const url = `${this.webconfig.baseUrls.app}/support/blockTerminal/${terminalId}`;
    const body = { reason };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async supportUnblockTerminal(terminalId: string, reason: string) {
    const url = `${this.webconfig.baseUrls.app}/support/unblockTerminal/${terminalId}`;
    const body = { reason };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async getMerchantBanks(): Promise<Array<string>> {
    const url = `${this.webconfig.baseUrls.app}/banks`;

    return this.httpRequest<Array<string>>('GET', url).toPromise();
  }

  public async validateBranchCode(branchCode: string): Promise<ValidateBranchResponse> {
    const url = `${this.webconfig.baseUrls.app}/banks/validateBranchCode/${branchCode}`;

    return this.httpRequest<ValidateBranchResponse>('POST', url)
      .pipe(map((response) => ({ ...response })))
      .toPromise();
  }

  public async linkDeviceForCheckout(deviceId: string) {
    const url = `${this.webconfig.baseUrls.app}/merchants/checkout/link/${deviceId}`;

    return this.httpRequest<{ deviceId: string; linked: boolean }>('POST', url).toPromise();
  }

  public async updateIntegratorApp(user: any) {
    const url = `${this.webconfig.baseUrls.app}/merchants/integratorApp/${user.userId}`;
    const body = { ...user, userId: undefined };

    return this.httpRequest('PUT', url, body).toPromise();
  }

  public async buildApk(buildMode: any, outputType: any) {
    const url = `${this.webconfig.baseUrls.app}/build`;
    const body = { buildMode, outputType };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async getStatistics(filter: any) {
    const baseUrl = `${this.webconfig.baseUrls.app}/statistics/activity`;
    const queryParams = `filter=${filter}`;
    const url = `${baseUrl}?${queryParams}`;

    return this.httpRequest<DashboardStatistics>('GET', url).toPromise();
  }

  public async getBackOfficeSearchResults(searchValue: any) {
    const baseUrl = `${this.webconfig.baseUrls.app}/search/backoffice`;
    const queryParams = `searchValue=${searchValue}`;
    const url = `${baseUrl}?${queryParams}`;

    return this.httpRequest<BackofficeSearchResult[]>('GET', url).toPromise();
  }

  public async provisionPortalInfrastructure(bucketName: string, buildName: string) {
    const url = `${this.webconfig.baseUrls.app}/infrastructure/provisionWebPortal`;
    const body = { bucketName, buildName };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async getCfDistributions(): Promise<PortalDistributionListResponse> {
    const url = `${this.webconfig.baseUrls.app}/infrastructure/listDistributions`;

    return this.httpRequest<any>('GET', url)
      .pipe(
        map((distribution) => {
          return {
            distributions: distribution,
          };
        }),
      )
      .toPromise();
  }

  public async getCardReaderDetails(merchantName: string) {
    const url = `${this.webconfig.baseUrls.app}/terminals/card-reader-details/${merchantName}`;

    return this.httpRequest<TerminalAllocation[]>('GET', url).toPromise();
  }

  public async getUserDevices(userId: string, params: any) {
    const queryParams = '?' + new URLSearchParams({ ...params }).toString();
    const url = `${this.webconfig.baseUrls.app}/terminals/user/${userId}${queryParams}`;

    return this.httpRequest<TerminalDeviceDetails[]>('GET', url).toPromise();
  }

  public async getMerchantDevices(merchantId: string, params: any) {
    const queryParams = '?' + new URLSearchParams({ ...params }).toString();
    const url = `${this.webconfig.baseUrls.app}/terminals/merchant/${merchantId}${queryParams}`;

    return this.httpRequest<TerminalDeviceDetails[]>('GET', url).toPromise();
  }

  public async createQrCode(qrCodeUrl: string) {
    const url = `${this.webconfig.baseUrls.app}/portal/qrCode`;
    const body = { url: qrCodeUrl };

    return this.httpRequest<QRCodeResponse>('POST', url, body).toPromise();
  }

  public async getPortalPresignedUrls(keys: any, signedUrlExpireSeconds: number) {
    const url = `${this.webconfig.baseUrls.app}/portal/portalPresignedUrls`;
    const body = { keys, signedUrlExpireSeconds };

    return this.httpRequest('POST', url, body).toPromise();
  }

  public async invokeCheckoutIntent(deviceInstallationId: string, args: any): Promise<{ success: boolean }> {
    const url = `${this.webconfig.baseUrls.app}/portal/invokeIntent/${deviceInstallationId}`;
    const body = args;

    return this.httpRequest<{ success: boolean }>('POST', url, body).toPromise();
  }

  public async generateSdkAccessKey(params: any) {
    const url = `${this.webconfig.baseUrls.app}/integrator/sdkAccess`;
    const body = params;

    return this.httpRequest<any>('POST', url, body).toPromise();
  }

  public getAppSocketServerUrl(): string {
    return this.webconfig.baseUrls.socket;
  }

  public getSoftPosUrl(): string {
    return this.softPosUrl;
  }

  public async sendNdaSignatoryEmail(params: any): Promise<any> {
    const url = `${this.webconfig.baseUrls.app}/portal/sendNdaSignatoryEmail`;
    const body = params;

    return this.httpRequest<any>('POST', url, body).toPromise();
  }

  public async sendSdkCredentialsEmail(): Promise<any> {
    const url = `${this.webconfig.baseUrls.app}/portal/email/credentials`;
    return this.httpRequest<any>('POST', url).toPromise();
  }

  public async getMarkdownDocs(path: string): Promise<string> {
    const url = `${this.webconfig.baseUrls.app}/portal/docs?path=${path}`;

    return await this.httpRequest<any>('GET', url).toPromise();
  }

  public setAttestationListeningMode(data: { enable: boolean; acquirerId: number }): Observable<{ success: boolean }> {
    const url = `${this.webconfig.baseUrls.app}/integrator/attestationListeningMode`;

    return this.httpRequest<{ success: boolean }>('POST', url, data);
  }

  public getAttestationListeningMode(acquirerId: number): Observable<{ success: boolean }> {
    const url = `${this.webconfig.baseUrls.app}/integrator/attestationListeningMode?acquirerId=${acquirerId}`;

    return this.httpRequest<{ success: boolean }>('GET', url);
  }

  public async paymentStatus(transactionId: string): Promise<string> {
    const url = `${this.webconfig.baseUrls.app}/merchants/checkout/paymentStatus/${transactionId}`;

    return await this.httpRequest<any>('GET', url).toPromise();
  }

  // ----------------------------------------------
  // Additional Calls
  // ----------------------------------------------

  public async getMerchantDocuments() {
    const {
      androidSampleApp,
      cordovaSampleApp,
      intentsIntegrationGuide,
      vodacomFraudFlyer,
      haloNdaDocument,
      haloDeveloperDocs,
      haloGithubRepo,
      haloGoAndroidApp,
    } = this;
    return {
      androidSampleApp,
      cordovaSampleApp,
      intentsIntegrationGuide,
      vodacomFraudFlyer,
      haloNdaDocument,
      haloDeveloperDocs,
      haloGithubRepo,
      haloGoAndroidApp,
    };
  }

  public async getCountries(): Promise<Country[]> {
    try {
      const res = await this.http.get(this.countriesJson, { responseType: 'json' }).toPromise();
      return res as Country[];
    } catch (error) {
      this.handleError('Error fetching countries:', error);
    }
  }

  private handleError(message: string, error: any): never {
    this.logger.error(message, error);
    throw error;
  }

  private httpRequest<T>(method: string, url: string, data: any = null, headers: any = {}) {
    try {
      this.logger.debug(`HTTP Request: ${method} ${url} ${data ? JSON.stringify(data) : ''}`);

      const requestOptions = {
        headers: new HttpHeaders(headers),
        body: data,
      };

      return this.http.request<T>(method, url, requestOptions);
    } catch (error) {
      this.handleError(`An error occurred during HTTP request ${method} ${url} request:`, error);
    }
  }
}
